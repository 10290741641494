import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"loji_rawatan_air":"LRA Tondulu I (Tambunan Lama)","average":8.8,"percentage":3.2},
  {"loji_rawatan_air":"LRA Tondulu II (ABJV) (Tambunan Baru)","average":3.6,"percentage":1.3},
  {"loji_rawatan_air":"LRA Kg Bingkor (ABJV)","average":19.1,"percentage":6.9},
  {"loji_rawatan_air":"LRA Kg Bunsit","average":49.1,"percentage":17.7},
  {"loji_rawatan_air":"LRA Kg Keningau","average":5.6,"percentage":2.0},
  {"loji_rawatan_air":"LRA Kg Agudon","average":12.7,"percentage":4.6},
  {"loji_rawatan_air":"LRA Pekan Sook I","average":1.2,"percentage":0.4},
  {"loji_rawatan_air":"LRA Sook II","average":1.7,"percentage":0.6},
  {"loji_rawatan_air":"LRA Kemabong","average":5.5,"percentage":2.0},
  {"loji_rawatan_air":"LRA Senagang I & II","average":13.4,"percentage":4.9},
  {"loji_rawatan_air":"LRA Pekan Tenom","average":3.1,"percentage":1.1},
  {"loji_rawatan_air":"LRA Montenior (ABJV) Beaufort 1","average":26.9,"percentage":9.7},
  {"loji_rawatan_air":"LRA Montenior (ABJV) Beaufort 2","average":29.3,"percentage":10.6},
  {"loji_rawatan_air":"LRA EWSS Limbawang","average":17.3,"percentage":6.3},
  {"loji_rawatan_air":"LRA Lawa Gadong","average":79.4,"percentage":28.7},
  {"loji_rawatan_air":"Total","average":276.6,"percentage":100.0}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationWtp() {
  return (
    <React.Fragment>
      <Title>Table 1 - WTP</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water User</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={2}>Water Usage</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Loji Rawatan Air (LRA)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average (MLD)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Percentage (%)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.loji_rawatan_air}</TableCell>
              <TableCell align="center">{row.average}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
