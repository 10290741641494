import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"name":"LRA Tondulu I (Tambunan Lama)","average":8.8,"percentage":1.6},
  {"name":"LRA Tondulu II (ABJV) (Tambunan Baru)","average":3.6,"percentage":0.6},
  {"name":"LRA Kg Bingkor (ABJV)","average":19.1,"percentage":3.4},
  {"name":"LRA Kg Bunsit","average":49.1,"percentage":8.7},
  {"name":"LRA Kg Keningau","average":5.6,"percentage":1.0},
  {"name":"LRA Kg Agudon","average":12.7,"percentage":2.2},
  {"name":"LRA Pekan Sook I","average":1.2,"percentage":0.2},
  {"name":"LRA Sook II","average":1.7,"percentage":0.3},
  {"name":"LRA Kemabong","average":5.5,"percentage":1.0},
  {"name":"LRA Senagang I & II","average":13.4,"percentage":2.4},
  {"name":"LRA Pekan Tenom","average":3.1,"percentage":0.5},
  {"name":"LRA Montenior (ABJV) Beaufort 1","average":26.9,"percentage":4.8},
  {"name":"LRA Montenior (ABJV) Beaufort 2","average":29.3,"percentage":5.2},
  {"name":"LRA EWSS Limbawang","average":17.3,"percentage":3.1},
  {"name":"LRA Lawa Gadong","average":79.4,"percentage":14.1},
  {"name":"RP Kiawayan (Kg Lalapakon)","average":30.2,"percentage":5.4},
  {"name":"RP Karanaan A6 (Kg Karanaan)","average":8.6,"percentage":1.5},
  {"name":"RP Timbou (Kg Karanaan)","average":8.6,"percentage":1.5},
  {"name":"RP Maras Karas A3E (Kg Maras Karas)","average":17.3,"percentage":3.1},
  {"name":"RP Stesen 3C Kg Lumondou","average":22.0,"percentage":3.9},
  {"name":"RP Kg Mangi Pangi","average":4.8,"percentage":0.8},
  {"name":"RP Kepayan","average":14.0,"percentage":2.5},
  {"name":"RP Bumburing II","average":25.9,"percentage":4.6},
  {"name":"RP Kg Mailo Transpegalan 2","average":64.8,"percentage":11.5},
  {"name":"RP Bumboring 1","average":17.3,"percentage":3.1},
  {"name":"RP Penagatan","average":30.2,"percentage":5.4},
  {"name":"RP Lagud Seberang","average":43.2,"percentage":7.7},
  {"name":"Total","average":563.6,"percentage":100.0}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationOverall() {
  return (
    <React.Fragment>
      <Title>Table 3 - Overall</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water User</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={2}>Water Usage</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>&nbsp;</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average (MLD)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Percentage (%)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.average}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
