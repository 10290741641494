import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';
import './WaterAllocation.css';

// https://shancarter.github.io/mr-data-converter/
const rows = [
  {"rumah_pump":"RP Kiawayan (Kg Lalapakon)","average":30.2,"percentage":10.5},
  {"rumah_pump":"RP Karanaan A6 (Kg Karanaan)","average":8.6,"percentage":3.0},
  {"rumah_pump":"RP Timbou (Kg Karanaan)","average":8.6,"percentage":3.0},
  {"rumah_pump":"RP Maras Karas A3E (Kg Maras Karas)","average":17.3,"percentage":6.0},
  {"rumah_pump":"RP Stesen 3C Kg Lumondou","average":22.0,"percentage":7.7},
  {"rumah_pump":"RP Kg Mangi Pangi","average":4.8,"percentage":1.7},
  {"rumah_pump":"RP Kepayan","average":14.0,"percentage":4.9},
  {"rumah_pump":"RP Bumburing II","average":25.9,"percentage":9.0},
  {"rumah_pump":"RP Kg Mailo Transpegalan 2","average":64.8,"percentage":22.6},
  {"rumah_pump":"RP Bumboring 1","average":17.3,"percentage":6.0},
  {"rumah_pump":"RP Penagatan","average":30.2,"percentage":10.5},
  {"rumah_pump":"RP Lagud Seberang","average":43.2,"percentage":15.1},
  {"rumah_pump":"Total","average":287.0,"percentage":100.0}
]


function preventDefault(event) {
  event.preventDefault();
}

export default function WaterAllocationIrrigation() {
  return (
    <React.Fragment>
      <Title>Table 2 - Irrigation</Title>
      <Table className="water-allocation-table" size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water User</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}} colSpan={2}>Water Usage</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Rumah Pump (RP)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average (MLD)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Percentage (%)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{row.rumah_pump}</TableCell>
              <TableCell align="center">{row.average}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
