import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import './WaterAllocation.css';

import Chart from './Chart';
import Deposits from './Deposits';
import Title from './Title';

import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CircularProgress from '@mui/material/CircularProgress';

//Table
import NestedList from './listItems';
import WaterQuality from './WaterQuality';
import WaterAudit from './WaterAudit';
import WaterAvailability from './WaterAvailability';
import WRIOptionTwo from './WRIOption2';
import WRITable from './WRITable';
import WaterDemand from './WaterDemand';
import WaterUser from './WaterUser';
import WaterAbstraction from './WaterAbstraction';
import WRITableFull from './WRITableFull';
import WRIRating from './WRIRating';
import WaterPriorityCategorization from './WaterPriorityCategorization';
import WaterPriority from './WaterPriority';
import WaterAssets from './WaterAssets';
import WaterLiabilities from './WaterLiabilities';
import WaterAssetsNet from './WaterAssetsNet';
import WaterStorageChanges from './WaterStorageChanges';
import WaterAllocationWtp from './WaterAllocationWtp';
import WaterAllocationIrrigation from './WaterAllocationIrrigation';
import WaterAllocationOverall from './WaterAllocationOverall';

//image
import logo from './../assets/NAWABS_logo.png';
import map from './../assets/sabah_map.png';
import wdMap from './../water-demand/wd-map.jpg';
import wpMap from './../water-prioritisation/wp-map.png';
import doeMap from './../water-quality/doe-map.jpg';
import wriMap from './../wri/wri-map.png';
import wAvailableMap from './../water-availability/wavailable-map.jpg';
import schemaPadas from './../water-account/schema-padas.jpg';
import wAuditMap from './../water-audit/waudit-map.jpg';

import wAcc from './../water-account/wacc-01.png'; 
import waPieChart from './../water-allocation/wa-pie-chart.png'; 

import wDemand from './../water-demand/wd-01.png';

import wAvail01 from './../water-availability/wa-01.png';
import wAvail02 from './../water-availability/wa-02.png';

import wriLra from './../wri/wri-lra.png';

import waMap from './../water-allocation/wa-map.jpg';
import wqDo from './../water-quality/wq-do.png';
import wqBod from './../water-quality/wq-bod.png';
import wqCod from './../water-quality/wq-cod.png';
import wqAn from './../water-quality/wq-an.png';
import wqTss from './../water-quality/wq-tss.png';
import wqpH from './../water-quality/wq-ph.png';
import wqi from './../water-quality/wqi.png';
import ecoli from './../water-quality/ecoli.png';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.water.gov.my/">
        Jabatan Pengairan Dan Saliran
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function SelectLabels() {
  const [term, setTerm] = React.useState('');
  const [scenario, setScenario] = React.useState('');
  const [startDate, setStartDate] = React.useState(dayjs('2022-12-01T00:00:00'));
  const [endDate, setEndDate] = React.useState(dayjs('2022-12-03T00:00:00'));

  const handleTermChange = (event) => {
    setTerm(event.target.value);
  };

  const handleScenarioChange = (event) => {
    setScenario(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  return (
    <div style={{paddingLeft: '24px', width: '100%', display: 'flex'}}>
      <FormControl sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="demo-simple-select-helper-label">Term</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={term}
          label="Term"
          onChange={handleTermChange}>

          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value={10}>Short Term</MenuItem>
          <MenuItem value={20}>Long Term</MenuItem>
          <MenuItem value={30}>Historical Data</MenuItem>
          <MenuItem value={40}>Drought Index</MenuItem>
        </Select>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="Start Date"
            inputFormat="MM/DD/YYYY"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} sx={{ m: 1, minWidth: 100, marginTop: '8px'}}/>}/>
          <MobileDatePicker
            label="End Date"
            inputFormat="MM/DD/YYYY"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} sx={{ m: 1, minWidth: 100, marginTop: '8px'}} />}/>
      </LocalizationProvider>
      <Button variant="contained" 
        sx={{backgroundColor: '#2e833c',marginTop: '8px', height: '53px', marginLeft: 'auto',
            ':hover': {
              bgcolor: '#4a3778', color: 'white',
            },}}>
          Export As</Button>
    </div>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

const outputs = ['Water Demand', 'Water Allocation', 'Water Priorisation', 'Water Resource Index & Drought Index', 
  'Water Availability', 'Water Quality', 'Dam Release & Storage', 'Water Accounting', 'Water Auditing'];

const maps = [wdMap, waMap, wpMap, wriMap, wAvailableMap, doeMap, map, schemaPadas, wAuditMap];

const DownloadCsvButton = ({csvUrl}) => {
  return <Button size="small" variant="contained" sx={{backgroundColor: '#2e833c',marginTop: '4px', marginLeft: 'auto',
  ':hover': {
    bgcolor: '#4a3778', color: 'white',
  },}} onClick={() => window.open(csvUrl, '_blank')}>CSV</Button>
}

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [show, toggleDetail] = React.useState(false);
  const [select, setSelection] = React.useState(-1);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const showDetail = () => {
    toggleDetail(true);
  };

  const hideDetail = () => {
    toggleDetail(false);
  };

  function onItemClickHandler(e){
    console.log("test");
    console.log(e.target);
    console.log("id: " + e.target.id);
    console.log("value: " + e.target.firstChild.nodeValue);

    for (let i = 0; i < outputs.length; i++) {
      if(outputs[i] === e.target.firstChild.nodeValue){
          console.log("no. " + i + ", value: " + outputs[i]);
          setSelection(i);
      }
    } 
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} sx={{backgroundColor:'#2e833c'}}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}>

              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}>

              {select < 0? 'Dashboard' : outputs[select]}
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <Box 
              component="img"
              sx={{ height: 43 }}
              alt="Logo"
              src={logo}
            />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" onClick={hideDetail}>
            <NestedList open={open} onClick={onItemClickHandler} />
            {/* {new NestedList()} */}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4}}>
            <Grid container spacing={3}>
              <SelectLabels sx={{ pt: 4 }} />

              <Grid item xs={12} sx={{pt: 8}}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Box 
                    component="img"
                    alt="Map"
                    src={select < 0? maps[0] : maps[select]}
                    onClick={showDetail}/>
                  {/* {new CircularDeterminate()} */}
                </Paper>
              </Grid>

              {select == 0 && show? showWaterDemand : ''}
              {select == 1 && show? showWaterAllocation : ''}
              {select == 2 && show? showWaterPriorisation : ''}
              {select == 3 && show? showWRI : ''}
              {select == 4 && show? showWaterAvailability : ''}
              {select == 5 && show? showWaterQuality : ''}
              {/* select == 6 == Dam & show nothing */}
              {select == 7 && show? showWaterAccounting : ''}
              {select == 8 && show? showWaterAudit : ''}

              {/* Chart */}
              {/* {show? new getChart() : ''} */}
              
              {/* Recent Deposits */}
              {/* {show? new getValue() : ''} */}

            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const showWaterDemand = (
  <>
    <Grid item xs={12} md={12} lg={12}>
      <Title>Water Demand Chart</Title>
      <Box 
          component="img"
          sx={{ width: '100%'}}
          alt="wDemand"
          src={wDemand}/>
          <Button size="small" variant="contained" sx={{backgroundColor: '#2e833c',marginTop: '4px', marginLeft: 'auto',
            ':hover': {
              bgcolor: '#4a3778', color: 'white',
            },}} onClick={() => window.open('/export/water_demand_table.csv', '_blank')}>CSV</Button>
    </Grid>
    {/* <Grid item xs={12} md={4} lg={4}>
      <WaterAbstraction sx={{p: 8}} />
    </Grid> */}
    <Grid item xs={12} md={12} lg={12}>
      <WaterUser sx={{p: 8}} />
    </Grid>
  </>
)

const showWaterAllocation = (
  <>
    <Grid item xs={12} md={12} lg={12}>
      <WaterAllocationWtp sx={{p: 8}} />
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
      <WaterAllocationIrrigation sx={{p: 8}} />
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
      <WaterAllocationOverall sx={{p: 8}} />
    </Grid>
    <Grid item xs={12} md={12} lg={12}>
      <Title>Water Usage</Title>
      <Box 
          component="img"
          sx={{ width: '100%'}}
          alt="wDemand"
          src={waPieChart}/>
          <Button size="small" variant="contained" sx={{backgroundColor: '#2e833c',marginTop: '4px', marginLeft: 'auto',
            ':hover': {
              bgcolor: '#4a3778', color: 'white',
            },}} onClick={() => window.open('/export/water_allocation.csv', '_blank')}>CSV</Button>
    </Grid>
  </>
)

const showWaterPriorisation = (
  <>
    <Grid item xs={12} md={12} lg={12} sx={{textAlign: 'center'}}>
      <Title>Water Resource Index(WRI)</Title>
    </Grid>

    <Grid item xs={12} md={3} lg={3}>
      <WRIRating sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={9} lg={9}>
      <WRITableFull sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={12} lg={12} sx={{textAlign: 'center'}}>
      <WaterPriorityCategorization sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={12} lg={12} sx={{textAlign: 'center'}}>
      <WaterPriority sx={{p: 8}} />
    </Grid>
  </>
)

const showWaterQuality = (
  <>
    <Grid item xs={12} sx={{pt: 8}}>
      <WaterQuality sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={6} lg={6}>
        <Title>Dissolved Oxygen</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqDo"
          src={wqDo}/>
          <DownloadCsvButton csvUrl='/export/wq_dissolved_oxygen.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>Biological Oxygen Demand</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqBod"
          src={wqBod}/>
          <DownloadCsvButton csvUrl='/export/wq_bod.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>Chemical Oxygen Demand</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqCod"
          src={wqCod}/>
          <DownloadCsvButton csvUrl='/export/wq_cod.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>Ammoniacal Nitrogen</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqAn"
          src={wqAn}/>
          <DownloadCsvButton csvUrl='/export/wq_na.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>Total Suspended Solid</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqTss"
          src={wqTss}/>
          <DownloadCsvButton csvUrl='/export/wq_ss.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>pH</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqpH"
          src={wqpH}/>
          <DownloadCsvButton csvUrl='/export/wq_ph.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>WQI</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="wqi"
          src={wqi}/>
          <DownloadCsvButton csvUrl='/export/wq_wqi.csv' />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Title>E-Coli</Title>
        <Box 
          component="img"
          sx={{ width: '100%' }}
          alt="ecoli"
          src={ecoli}/>
          <DownloadCsvButton csvUrl='/export/wq_ecoli.csv' />
      </Grid>
  </>
)

const showWRI = (
  <>
    <Grid item xs={12} md={12} lg={12} >
      <Box 
        component="img"
        sx={{ width: '100%'}}
        alt="wriLra"
        src={wriLra}/>
      <DownloadCsvButton csvUrl='/export/wri_table.csv'/>
      <Grid sx={{marginTop: 2.5}}></Grid>
      <WRITable sx={{p: 8}} />
    </Grid>
  </>
)

const showWaterAvailability = (
  <>
    <Grid item xs={12} md={6} lg={6}>
      <Box 
        component="img"
        sx={{ width: '100%' }}
        alt="wAvail01"
        src={wAvail01}/>
        <Button size="small" variant="contained" sx={{backgroundColor: '#2e833c',marginTop: '4px', marginLeft: 'auto',
            ':hover': {
              bgcolor: '#4a3778', color: 'white',
            },}} onClick={() => window.open('/export/water_availability_table.csv', '_blank')}>CSV</Button>
    </Grid>
    <Grid item xs={12} md={6} lg={6}>
      <Box 
        component="img"
        sx={{ width: '100%' }}
        alt="wAvail02"
        src={wAvail02}/>
        <Button size="small" variant="contained" sx={{backgroundColor: '#2e833c',marginTop: '4px', marginLeft: 'auto',
            ':hover': {
              bgcolor: '#4a3778', color: 'white',
            },}} onClick={() => window.open('/export/water_availability_table.csv', '_blank')}>CSV</Button>
    </Grid>
  </>
)

const showWaterAccounting = (
  <>
    <Grid item xs={12} md={12} lg={12}>
      <Title>Physical Flows Mass Balance Diagram</Title>
      <Box 
          component="img"
          sx={{ width: '100%'}}
          alt="wAcc"
          src={wAcc}/>
    </Grid>

    <Grid item xs={12} md={12} lg={12} sx={{textAlign: 'center'}}>
      {/* <Title sx={{fontWeight: 'bold', color: 'black'}}>Statement of Water Assets and Water Liabilities</Title> */}
      <Typography component="h2" variant="h6" color="black" fontWeight="bold" noWrap sx={{ flexGrow: 1, textDecoration: 'underline'}}>
        Statement of Water Assets and Water Liabilities
      </Typography>
    </Grid>

    <Grid item xs={12} md={12} lg={12}>
      <WaterAssets sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={12} lg={12}>
      <WaterLiabilities sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={12} lg={12}>
      <WaterAssetsNet sx={{p: 8}} />
    </Grid>

    <Grid item xs={12} md={12} lg={12} sx={{textAlign: 'center'}}>
      <Typography component="h2" variant="h6" color="black" fontWeight="bold" noWrap sx={{ flexGrow: 1, textDecoration: 'underline'}}>
        Statement of Changes in Water Assets and Water Liabilities
      </Typography>
    </Grid>

    <Grid item xs={12} md={12} lg={12}>
      <WaterStorageChanges sx={{p: 8}} />
    </Grid>
  </>
)

const showWaterAudit = (
  <>
    <Grid item xs={12} sx={{pt: 8}}>
      <WaterAudit sx={{p: 8}} />
    </Grid>
  </>
)

function getChart() {
  return (
    <Grid item xs={12} md={8} lg={9}>
      <Box component="div">
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <Chart />
        </Paper>
      </Box>
    </Grid>
  );
}

function getValue() {
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Box component="div">
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <Deposits />
        </Paper>
      </Box>
    </Grid>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
