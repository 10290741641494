import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(date, capacity, abstraction) {
  id = id + 1;
  return { id, date, capacity, abstraction};
}

const rows = [
  createData('2023/1/1', '0.069', '0.070'), 
  createData('2023/1/2', '0.069', '0.070'), 
  createData('2023/1/3', '0.069', '0.070'), 
  createData('2023/1/4', '0.069', '0.070'), 
  createData('2023/1/5', '0.069', '0.070'), 
  createData('2023/1/6', '0.069', '0.070'), 
  createData('2023/1/7', '0.069', '0.070'), 
  createData('2023/1/8', '0.069', '0.070'), 
  createData('2023/1/9', '0.069', '0.070'), 
  createData('2023/1/10', '0.069', '0.070'), 
  createData('2023/1/11', '0.069', '0.070'), 
  createData('2023/1/12', '0.069', '0.070'), 
  createData('2023/1/13', '0.069', '0.070'), 
  createData('2023/1/14', '0.069', '0.070'), 
  createData('2023/1/15', '0.069', '0.070'), 
  createData('2023/1/16', '0.069', '0.070'), 
  createData('2023/1/17', '0.069', '0.070'), 
  createData('2023/1/18', '0.069', '0.070'), 
  createData('2023/1/19', '0.069', '0.070'), 
  createData('2023/1/20', '0.069', '0.070'), 
  createData('2023/1/21', '0.069', '0.070'), 
  createData('2023/1/22', '0.069', '0.070'), 
  createData('2023/1/23', '0.069', '0.070'), 
  createData('2023/1/24', '0.069', '0.070'), 
  createData('2023/1/25', '0.069', '0.070'), 
  createData('2023/1/26', '0.069', '0.070'), 
  createData('2023/1/27', '0.069', '0.070'), 
  createData('2023/1/28', '0.069', '0.070'), 
  createData('2023/1/29', '0.069', '0.070'), 
  createData('2023/1/30', '0.069', '0.070'), 
  createData('2023/1/31', '0.069', '0.070'), 
  createData('2023/2/1', '0.069', '0.070'), 
  createData('2023/2/2', '0.069', '0.070'), 
  createData('2023/2/3', '0.069', '0.070'), 
  createData('2023/2/4', '0.069', '0.070'), 
  createData('2023/2/5', '0.069', '0.070'), 
  createData('2023/2/6', '0.069', '0.070'), 
  createData('2023/2/7', '0.069', '0.070'), 
  createData('2023/2/8', '0.069', '0.070'), 
  createData('2023/2/9', '0.069', '0.070'), 
  createData('2023/2/10', '0.069', '0.070'), 
  createData('2023/2/11', '0.069', '0.070'), 
  createData('2023/2/12', '0.069', '0.070'), 
  createData('2023/2/13', '0.069', '0.070'), 
  createData('2023/2/14', '0.069', '0.070'), 
  createData('2023/2/15', '0.069', '0.070'), 
  createData('2023/2/16', '0.069', '0.070'), 
  createData('2023/2/17', '0.069', '0.070'), 
  createData('2023/2/18', '0.069', '0.070'), 
  createData('2023/2/19', '0.069', '0.070'), 
  createData('2023/2/20', '0.069', '0.070'), 
  createData('2023/2/21', '0.069', '0.070'), 
  createData('2023/2/22', '0.069', '0.070'), 
  createData('2023/2/23', '0.069', '0.070'), 
  createData('2023/2/24', '0.069', '0.070'), 
  createData('2023/2/25', '0.069', '0.070'), 
  createData('2023/2/26', '0.069', '0.070'), 
  createData('2023/2/27', '0.069', '0.070'), 
  createData('2023/2/28', '0.069', '0.070'), 
  createData('2023/3/1', '0.069', '0.070'), 
  createData('2023/3/2', '0.069', '0.070'), 
  createData('2023/3/3', '0.069', '0.070'), 
  createData('2023/3/4', '0.069', '0.070'), 
  createData('2023/3/5', '0.069', '0.070'), 
  createData('2023/3/6', '0.069', '0.070'), 
  createData('2023/3/7', '0.069', '0.070'), 
  createData('2023/3/8', '0.069', '0.070'), 
  createData('2023/3/9', '0.069', '0.070'), 
  createData('2023/3/10', '0.069', '0.070'), 
  createData('2023/3/11', '0.069', '0.070'), 
  createData('2023/3/12', '0.069', '0.070'), 
  createData('2023/3/13', '0.069', '0.070'), 
  createData('2023/3/14', '0.069', '0.070'), 
  createData('2023/3/15', '0.069', '0.070'), 
  createData('2023/3/16', '0.069', '0.070'), 
  createData('2023/3/17', '0.069', '0.070'), 
  createData('2023/3/18', '0.069', '0.070'), 
  createData('2023/3/19', '0.069', '0.070'), 
  createData('2023/3/20', '0.069', '0.070'), 
  createData('2023/3/21', '0.069', '0.070'), 
  createData('2023/3/22', '0.069', '0.070'), 
  createData('2023/3/23', '0.069', '0.070'), 
  createData('2023/3/24', '0.069', '0.070'), 
  createData('2023/3/25', '0.069', '0.070'), 
  createData('2023/3/26', '0.069', '0.070'), 
  createData('2023/3/27', '0.069', '0.070'), 
  createData('2023/3/28', '0.069', '0.070'), 
  createData('2023/3/29', '0.069', '0.070'), 
  createData('2023/3/30', '0.069', '0.070'), 
  createData('2023/3/31', '0.069', '0.070'), 
  createData('2023/4/1', '0.069', '0.070'), 
  createData('2023/4/2', '0.069', '0.070'), 
  createData('2023/4/3', '0.069', '0.070'), 
  createData('2023/4/4', '0.069', '0.070'), 
  createData('2023/4/5', '0.069', '0.070'), 
  createData('2023/4/6', '0.069', '0.070'), 
  createData('2023/4/7', '0.069', '0.070'), 
  createData('2023/4/8', '0.069', '0.070'), 
  createData('2023/4/9', '0.069', '0.070'), 
  createData('2023/4/10', '0.069', '0.070'), 
  createData('2023/4/11', '0.069', '0.070'), 
  createData('2023/4/12', '0.069', '0.070'), 
  createData('2023/4/13', '0.069', '0.070'), 
  createData('2023/4/14', '0.069', '0.070'), 
  createData('2023/4/15', '0.069', '0.070'), 
  createData('2023/4/16', '0.069', '0.070'), 
  createData('2023/4/17', '0.069', '0.070'), 
  createData('2023/4/18', '0.069', '0.070'), 
  createData('2023/4/19', '0.069', '0.070'), 
  createData('2023/4/20', '0.069', '0.070'), 
  createData('2023/4/21', '0.069', '0.070'), 
  createData('2023/4/22', '0.069', '0.070'), 
  createData('2023/4/23', '0.069', '0.070'), 
  createData('2023/4/24', '0.069', '0.070'), 
  createData('2023/4/25', '0.069', '0.070'), 
  createData('2023/4/26', '0.069', '0.070'), 
  createData('2023/4/27', '0.069', '0.070'), 
  createData('2023/4/28', '0.069', '0.070'), 
  createData('2023/4/29', '0.069', '0.070'), 
  createData('2023/4/30', '0.069', '0.070'), 
  createData('2023/5/1', '0.069', '0.075'), 
  createData('2023/5/2', '0.069', '0.075'), 
  createData('2023/5/3', '0.069', '0.075'), 
  createData('2023/5/4', '0.069', '0.075'), 
  createData('2023/5/5', '0.069', '0.075'), 
  createData('2023/5/6', '0.069', '0.075'), 
  createData('2023/5/7', '0.069', '0.075'), 
  createData('2023/5/8', '0.069', '0.075'), 
  createData('2023/5/9', '0.069', '0.075'), 
  createData('2023/5/10', '0.069', '0.075'), 
  createData('2023/5/11', '0.069', '0.075'), 
  createData('2023/5/12', '0.069', '0.075'), 
  createData('2023/5/13', '0.069', '0.075'), 
  createData('2023/5/14', '0.069', '0.075'), 
  createData('2023/5/15', '0.069', '0.075'), 
  createData('2023/5/16', '0.069', '0.075'), 
  createData('2023/5/17', '0.069', '0.075'), 
  createData('2023/5/18', '0.069', '0.075'), 
  createData('2023/5/19', '0.069', '0.075'), 
  createData('2023/5/20', '0.069', '0.075'), 
  createData('2023/5/21', '0.069', '0.075'), 
  createData('2023/5/22', '0.069', '0.075'), 
  createData('2023/5/23', '0.069', '0.075'), 
  createData('2023/5/24', '0.069', '0.075'), 
  createData('2023/5/25', '0.069', '0.075'), 
  createData('2023/5/26', '0.069', '0.075'), 
  createData('2023/5/27', '0.069', '0.075'), 
  createData('2023/5/28', '0.069', '0.075'), 
  createData('2023/5/29', '0.069', '0.075'), 
  createData('2023/5/30', '0.069', '0.075'), 
  createData('2023/5/31', '0.069', '0.075'), 
  createData('2023/6/1', '0.069', '0.072'), 
  createData('2023/6/2', '0.069', '0.072'), 
  createData('2023/6/3', '0.069', '0.072'), 
  createData('2023/6/4', '0.069', '0.072'), 
  createData('2023/6/5', '0.069', '0.072'), 
  createData('2023/6/6', '0.069', '0.072'), 
  createData('2023/6/7', '0.069', '0.072'), 
  createData('2023/6/8', '0.069', '0.072'), 
  createData('2023/6/9', '0.069', '0.072'), 
  createData('2023/6/10', '0.069', '0.072'), 
  createData('2023/6/11', '0.069', '0.072'), 
  createData('2023/6/12', '0.069', '0.072'), 
  createData('2023/6/13', '0.069', '0.072'), 
  createData('2023/6/14', '0.069', '0.072'), 
  createData('2023/6/15', '0.069', '0.072'), 
  createData('2023/6/16', '0.069', '0.072'), 
  createData('2023/6/17', '0.069', '0.072'), 
  createData('2023/6/18', '0.069', '0.072'), 
  createData('2023/6/19', '0.069', '0.072'), 
  createData('2023/6/20', '0.069', '0.072'), 
  createData('2023/6/21', '0.069', '0.072'), 
  createData('2023/6/22', '0.069', '0.072'), 
  createData('2023/6/23', '0.069', '0.072'), 
  createData('2023/6/24', '0.069', '0.072'), 
  createData('2023/6/25', '0.069', '0.072'), 
  createData('2023/6/26', '0.069', '0.072'), 
  createData('2023/6/27', '0.069', '0.072'), 
  createData('2023/6/28', '0.069', '0.072'), 
  createData('2023/6/29', '0.069', '0.072'), 
  createData('2023/6/30', '0.069', '0.072'), 
  createData('2023/7/1', '0.069', '0.072'), 
  createData('2023/7/2', '0.069', '0.072'), 
  createData('2023/7/3', '0.069', '0.072'), 
  createData('2023/7/4', '0.069', '0.072'), 
  createData('2023/7/5', '0.069', '0.072'), 
  createData('2023/7/6', '0.069', '0.072'), 
  createData('2023/7/7', '0.069', '0.072'), 
  createData('2023/7/8', '0.069', '0.072'), 
  createData('2023/7/9', '0.069', '0.072'), 
  createData('2023/7/10', '0.069', '0.072'), 
  createData('2023/7/11', '0.069', '0.072'), 
  createData('2023/7/12', '0.069', '0.072'), 
  createData('2023/7/13', '0.069', '0.072'), 
  createData('2023/7/14', '0.069', '0.072'), 
  createData('2023/7/15', '0.069', '0.072'), 
  createData('2023/7/16', '0.069', '0.072'), 
  createData('2023/7/17', '0.069', '0.072'), 
  createData('2023/7/18', '0.069', '0.072'), 
  createData('2023/7/19', '0.069', '0.072'), 
  createData('2023/7/20', '0.069', '0.072'), 
  createData('2023/7/21', '0.069', '0.072'), 
  createData('2023/7/22', '0.069', '0.072'), 
  createData('2023/7/23', '0.069', '0.072'), 
  createData('2023/7/24', '0.069', '0.072'), 
  createData('2023/7/25', '0.069', '0.072'), 
  createData('2023/7/26', '0.069', '0.072'), 
  createData('2023/7/27', '0.069', '0.072'), 
  createData('2023/7/28', '0.069', '0.072'), 
  createData('2023/7/29', '0.069', '0.072'), 
  createData('2023/7/30', '0.069', '0.072'), 
  createData('2023/7/31', '0.069', '0.072'), 
  createData('2023/8/1', '0.069', '0.072'), 
  createData('2023/8/2', '0.069', '0.072'), 
  createData('2023/8/3', '0.069', '0.072'), 
  createData('2023/8/4', '0.069', '0.072'), 
  createData('2023/8/5', '0.069', '0.072'), 
  createData('2023/8/6', '0.069', '0.072'), 
  createData('2023/8/7', '0.069', '0.072'), 
  createData('2023/8/8', '0.069', '0.072'), 
  createData('2023/8/9', '0.069', '0.072'), 
  createData('2023/8/10', '0.069', '0.072'), 
  createData('2023/8/11', '0.069', '0.072'), 
  createData('2023/8/12', '0.069', '0.072'), 
  createData('2023/8/13', '0.069', '0.072'), 
  createData('2023/8/14', '0.069', '0.072'), 
  createData('2023/8/15', '0.069', '0.072'), 
  createData('2023/8/16', '0.069', '0.072'), 
  createData('2023/8/17', '0.069', '0.072'), 
  createData('2023/8/18', '0.069', '0.072'), 
  createData('2023/8/19', '0.069', '0.072'), 
  createData('2023/8/20', '0.069', '0.072'), 
  createData('2023/8/21', '0.069', '0.072'), 
  createData('2023/8/22', '0.069', '0.072'), 
  createData('2023/8/23', '0.069', '0.066'),
  createData('2023/8/24', '0.069', '0.066'), 
  createData('2023/8/25', '0.069', '0.066'), 
  createData('2023/8/26', '0.069', '0.066'), 
  createData('2023/8/27', '0.069', '0.066'), 
  createData('2023/8/28', '0.069', '0.066'), 
  createData('2023/8/29', '0.069', '0.066'), 
  createData('2023/8/30', '0.069', '0.066'), 
  createData('2023/8/31', '0.069', '0.066'), 
  createData('2023/9/1', '0.069', '0.066'), 
  createData('2023/9/2', '0.069', '0.066'), 
  createData('2023/9/3', '0.069', '0.066'), 
  createData('2023/9/4', '0.069', '0.066'), 
  createData('2023/9/5', '0.069', '0.066'), 
  createData('2023/9/6', '0.069', '0.066'), 
  createData('2023/9/7', '0.069', '0.066'), 
  createData('2023/9/8', '0.069', '0.066'), 
  createData('2023/9/9', '0.069', '0.066'), 
  createData('2023/9/10', '0.069', '0.066'), 
  createData('2023/9/11', '0.069', '0.066'), 
  createData('2023/9/12', '0.069', '0.066'), 
  createData('2023/9/13', '0.069', '0.066'), 
  createData('2023/9/14', '0.069', '0.070'), 
  createData('2023/9/15', '0.069', '0.070'), 
  createData('2023/9/16', '0.069', '0.070'), 
  createData('2023/9/17', '0.069', '0.070'), 
  createData('2023/9/18', '0.069', '0.070'), 
  createData('2023/9/19', '0.069', '0.070'), 
  createData('2023/9/20', '0.069', '0.070'), 
  createData('2023/9/21', '0.069', '0.070'), 
  createData('2023/9/22', '0.069', '0.070'), 
  createData('2023/9/23', '0.069', '0.070'), 
  createData('2023/9/24', '0.069', '0.070'), 
  createData('2023/9/25', '0.069', '0.070'), 
  createData('2023/9/26', '0.069', '0.070'), 
  createData('2023/9/27', '0.069', '0.070'), 
  createData('2023/9/28', '0.069', '0.070'), 
  createData('2023/9/29', '0.069', '0.070'), 
  createData('2023/9/30', '0.069', '0.070'), 
  createData('2023/10/1', '0.069', '0.070'), 
  createData('2023/10/2', '0.069', '0.070'), 
  createData('2023/10/3', '0.069', '0.070'), 
  createData('2023/10/4', '0.069', '0.070'), 
  createData('2023/10/5', '0.069', '0.070'), 
  createData('2023/10/6', '0.069', '0.070'), 
  createData('2023/10/7', '0.069', '0.070'), 
  createData('2023/10/8', '0.069', '0.070'), 
  createData('2023/10/9', '0.069', '0.070'), 
  createData('2023/10/10', '0.069', '0.070'), 
  createData('2023/10/11', '0.069', '0.070'), 
  createData('2023/10/12', '0.069', '0.070'), 
  createData('2023/10/13', '0.069', '0.070'), 
  createData('2023/10/14', '0.069', '0.070'), 
  createData('2023/10/15', '0.069', '0.070'), 
  createData('2023/10/16', '0.069', '0.070'), 
  createData('2023/10/17', '0.069', '0.070'), 
  createData('2023/10/18', '0.069', '0.070'), 
  createData('2023/10/19', '0.069', '0.070'), 
  createData('2023/10/20', '0.069', '0.070'), 
  createData('2023/10/21', '0.069', '0.070'), 
  createData('2023/10/22', '0.069', '0.070'), 
  createData('2023/10/23', '0.069', '0.070'), 
  createData('2023/10/24', '0.069', '0.070'), 
  createData('2023/10/25', '0.069', '0.070'), 
  createData('2023/10/26', '0.069', '0.070'), 
  createData('2023/10/27', '0.069', '0.070'), 
  createData('2023/10/28', '0.069', '0.070'), 
  createData('2023/10/29', '0.069', '0.070'), 
  createData('2023/10/30', '0.069', '0.070'), 
  createData('2023/10/31', '0.069', '0.070'), 
  createData('2023/11/1', '0.069', '0.070'), 
  createData('2023/11/2', '0.069', '0.070'), 
  createData('2023/11/3', '0.069', '0.070'), 
  createData('2023/11/4', '0.069', '0.070'), 
  createData('2023/11/5', '0.069', '0.070'), 
  createData('2023/11/6', '0.069', '0.070'), 
  createData('2023/11/7', '0.069', '0.070'), 
  createData('2023/11/8', '0.069', '0.070'), 
  createData('2023/11/9', '0.069', '0.070'), 
  createData('2023/11/10', '0.069', '0.070'), 
  createData('2023/11/11', '0.069', '0.070'), 
  createData('2023/11/12', '0.069', '0.070'), 
  createData('2023/11/13', '0.069', '0.070'), 
  createData('2023/11/14', '0.069', '0.070'), 
  createData('2023/11/15', '0.069', '0.070'), 
  createData('2023/11/16', '0.069', '0.070'), 
  createData('2023/11/17', '0.069', '0.070'), 
  createData('2023/11/18', '0.069', '0.070'), 
  createData('2023/11/19', '0.069', '0.070'), 
  createData('2023/11/20', '0.069', '0.070'), 
  createData('2023/11/21', '0.069', '0.070'), 
  createData('2023/11/22', '0.069', '0.070'), 
  createData('2023/11/23', '0.069', '0.070'), 
  createData('2023/11/24', '0.069', '0.070'), 
  createData('2023/11/25', '0.069', '0.070'), 
  createData('2023/11/26', '0.069', '0.070'), 
  createData('2023/11/27', '0.069', '0.070'), 
  createData('2023/11/28', '0.069', '0.070'), 
  createData('2023/11/29', '0.069', '0.070'), 
  createData('2023/11/30', '0.069', '0.070'), 
  createData('2023/12/1', '0.069', '0.070'), 
  createData('2023/12/2', '0.069', '0.070'), 
  createData('2023/12/3', '0.069', '0.070'), 
  createData('2023/12/4', '0.069', '0.070'), 
  createData('2023/12/5', '0.069', '0.070'), 
  createData('2023/12/6', '0.069', '0.070'), 
  createData('2023/12/7', '0.069', '0.070'), 
  createData('2023/12/8', '0.069', '0.070'), 
  createData('2023/12/9', '0.069', '0.070'), 
  createData('2023/12/10', '0.069', '0.070'), 
  createData('2023/12/11', '0.069', '0.070'), 
  createData('2023/12/12', '0.069', '0.070'), 
  createData('2023/12/13', '0.069', '0.070'), 
  createData('2023/12/14', '0.069', '0.070'), 
  createData('2023/12/15', '0.069', '0.070'), 
  createData('2023/12/16', '0.069', '0.070'), 
  createData('2023/12/17', '0.069', '0.070'), 
  createData('2023/12/18', '0.069', '0.070'), 
  createData('2023/12/19', '0.069', '0.070'), 
  createData('2023/12/20', '0.069', '0.070'), 
  createData('2023/12/21', '0.069', '0.070'), 
  createData('2023/12/22', '0.069', '0.070'), 
  createData('2023/12/23', '0.069', '0.070'), 
  createData('2023/12/24', '0.069', '0.070'), 
  createData('2023/12/25', '0.069', '0.070'), 
  createData('2023/12/26', '0.069', '0.070'), 
  createData('2023/12/27', '0.069', '0.070'), 
  createData('2023/12/28', '0.069', '0.070'), 
  createData('2023/12/29', '0.069', '0.070'), 
  createData('2023/12/30', '0.069', '0.070'), 
  createData('2023/12/31', '0.069', '0.070'), 
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Water Demand Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Date</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Design Capacity (m<sup>3</sup>/s)</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Abstraction (m<sup>3</sup>/s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.capacity}</TableCell>
              <TableCell align="center">{row.abstraction}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
