import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import './WaterStorageChanges.css';

let id = -1
// Generate Order Data
function createData(wtp, year18, year17) {
  id = id + 1;
  return { id, wtp, year18, year17};
}

const rows = [
  createData('Extraction from River (WTP)', '', ''),
  createData('1. Sg Kepong WTP', '0.89 ', '0.95'),
  createData('2. Sg Gombak WTP', '12.02 ', '13.14'),
  createData('3. Sg Rumput WTP', '0.43 ', '0.43'),
  createData('4. Wangsa Maju WTP  (from Gombak River Intake)', '2.51 ', '0.54'),
  createData('5. Ampang Intake WTP', '6.94 ', '6.69'),
];

const arows = [
  createData('1, Sg Batu WTP', '1.50 ', '0.00'), 
  createData('2, Sg Kepong WTP', '0.00 ', '0.30'), 
  createData('3, Sg Gombak WTP', '0.00 ', '1.26'), 
  createData('4, Sg Rumput WTP', '0.00 ', '0.04'), 
  createData('5, Wangsa Maju WTP', '1.70 ', '7.21'), 
  createData('6, Bukit Nanas WTP', '0.00 ', '0.00'), 
  createData('7, Ampang Intake WTP', '0.26 ', '0.35'), 
];

const brows = [
  createData('1, Sg Batu WTP', '0.00 ', '9.31'),
  createData('2, Sg Kepong WTP', '0.06 ', '0.00'),
  createData('3, Sg Gombak WTP', '1.12 ', '0.00'),
  createData('4, Sg Rumput WTP', '0.00 ', '0.00'),
  createData('5, Wangsa Maju WTP', '0.00 ', '0.00'),
  createData('6, Bukit Nanas WTP', '2.51 ', '2.92'),
  createData('7, Ampang Intake WTP', '0.00 ', '0.00'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>1. Changes in Surface Water Storage (Physical Water Balance)</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontWeight: 'bold'}}>Surface Water Storage Increase</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>31 Dec 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>31 Dec 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>&nbsp;Batu Dam</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dam Catchment Inflow</TableCell>
            <TableCell>64</TableCell>
            <TableCell></TableCell>
            <TableCell>65</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;Klang Gates Dam</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dam Catchment Inflow</TableCell>
            <TableCell>72</TableCell>
            <TableCell></TableCell>
            <TableCell>100</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;River</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dam Catchment Inflow</TableCell>
            <TableCell>1646</TableCell>
            <TableCell></TableCell>
            <TableCell>1552</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inflow from Dam Outflows</TableCell>
            <TableCell>23</TableCell>
            <TableCell></TableCell>
            <TableCell>44</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Surface Water Storage Increases (L<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>1804</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>1761</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontStyle: 'italic'}}>Change in Water Surface Storage</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>5</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <Table>Changes in Storage</Table> */}
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontWeight: 'bold'}}>Surface Water Storage Decreases</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Year 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Year 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>&nbsp;Batu Dam</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outflows</TableCell>
            <TableCell>12.15</TableCell>
            <TableCell></TableCell>
            <TableCell>7.88</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Direct Transfer (To Sg Batu WTP)</TableCell>
            <TableCell>44.11</TableCell>
            <TableCell></TableCell>
            <TableCell>42.61</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;Klang Gates Dam</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outflows</TableCell>
            <TableCell>10.56</TableCell>
            <TableCell></TableCell>
            <TableCell>35.62</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Direct Transfer (To Wangsa Maju WTP)</TableCell>
            <TableCell>14.32</TableCell>
            <TableCell></TableCell>
            <TableCell>14.59</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Direct Transfer (To Bukit Nanas WTP)</TableCell>
            <TableCell>43.54</TableCell>
            <TableCell></TableCell>
            <TableCell>46.05</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;River</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.wtp}</TableCell>
              <TableCell>{row.year18}</TableCell>
              <TableCell></TableCell>
              <TableCell>{row.year17}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Surface Water Storage Decreases (D<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>147</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>168</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Unaccounted Volume (Balancing Item) (U<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell>1652</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontStyle: 'italic'}}>Net Surface Water Storage Inflow (I<sub>sws</sub> - D<sub>sws</sub> - U<sub>sws</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>5</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <Table>Changes in Allocation </Table> */}
      <Grid></Grid>
      <Title>2. Changes in Allocation Accounts</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontWeight: 'bold'}}>Allocation Account Increases</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Year 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Year 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>&nbsp;Water Treatment Plants</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {arows.map((row) => (
            <TableRow key={row.id}>
              <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.wtp}</TableCell>
              <TableCell>{row.year18}</TableCell>
              <TableCell></TableCell>
              <TableCell>{row.year17}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Allocation Account Increases (I<sub>AA</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>3.46</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>9.17</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontWeight: 'bold'}}>Allocation Account Decreases</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Year 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold'}}>Year 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>&nbsp;Water Treatment Plants</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {brows.map((row) => (
            <TableRow key={row.id}>
              <TableCell >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.wtp}</TableCell>
              <TableCell>{row.year18}</TableCell>
              <TableCell></TableCell>
              <TableCell>{row.year17}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Allocation Account Decreases (D<sub>AA</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>3.69</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>12.24</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontStyle: 'italic'}}>Net Allocation Account Balance Increase (I<sub>AA</sub> - D<sub>AA</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>(0.23)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontStyle: 'italic'}}>(3.06)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid></Grid>
      <Title>3. Changes in Net Surface Water Assets</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}></TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold', backgroundColor: '#ebebeb'}}>Year 2018</TableCell>
            <TableCell colSpan={2} align="center" sx={{fontWeight: 'bold', backgroundColor: '#ebebeb'}}>Year 2017</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{backgroundColor: '#ebebeb'}}>
            <TableCell sx={{fontWeight: 'bold'}}>Changes in Net Surface Water Assets (I<sub>sws</sub> - D<sub>sws</sub> - U<sub>sws</sub> - I<sub>AA</sub> + D<sub>AA</sub>)</TableCell>
            <TableCell></TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>6</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid></Grid>
      <TablePart4 />
    </React.Fragment>
  );
}

class TablePart4 extends React.Component {
  render() {
    return (
      <table id="table-part-4">
        <tr>
          <td colSpan={5}></td>
            <td>Volume (MCM)</td>
            <td>Total (MCM)</td>
        </tr>
        <tr>
            <td colSpan={4}>Total Water Assets as on 31st Dec 2018 (WARP)*</td>
            <td></td>
            <td></td>
            <td>68</td>
        </tr>
        <tr>
            <td colSpan={4}>Plus: Water assets increases within 12 months of reporting date (WAI)</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={3}>Batu Dam</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>Dam Catchment Inflow</td>
            <td></td>
            <td>74</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={3}>Klang Gates Dam</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>Dam Catchment Inflow</td>
            <td></td>
            <td>86</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td>River</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>Catchment Inflows</td>
            <td></td>
            <td>1,468</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>Inflow from Dam Outflows</td>
            <td></td>
            <td>49</td>
            <td>1,677</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td colSpan={5}>Less: Water assets not available to be accessed and taken or delivered within 12 months of reporting date (WANA)</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={3}>Batu Dam</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>Outflows (Release + Spill)</td>
            <td></td>
            <td>28</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={3}>Klang Gates Dam</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>Outflows (Release + Spill)</td>
            <td></td>
            <td>21</td>
            <td>49</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td colSpan={5}>Water assets available to be accessed and taken or delivered within 12 months of reporting date</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>1,696</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td colSpan={5}>Less: Water liabilities and future commitments expected to be settled within 12 months of reporting date </td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={4}>Water liabilities expected to be delivered within 12 months of reporting date (WLE)</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={3}>Water Treatment Plants</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>1</span>Sg Batu WTP</td>
            <td></td>
            <td>42</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>2</span>Sg Kepong WTP</td>
            <td></td>
            <td>2</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>3</span>Sg Gombak WTP</td>
            <td></td>
            <td>8</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>4</span>Sg Rumput WTP</td>
            <td></td>
            <td>1</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>5</span>Wangsa Maju WTP</td>
            <td></td>
            <td>16</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>6</span>Bukit Nanas WTP</td>
            <td></td>
            <td>53</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td colSpan={2}><span style={{marginRight: "20px"}}>7</span>Ampang Intake WTP</td>
            <td></td>
            <td>7</td>
            <td>128</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={4}>Future Commitments expected to be delivered within 12 months of reporting date (FC)</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td colSpan={3}>New Water Treatment Plants</td>
            <td></td>
            <td>0</td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>128</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td colSpan={5}>Surplus of available water assets over water liabilites and future commitments expected to be settled within 12 months of the reporting date (SWA)</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td colSpan={5}>SWA = (WARP + WAI - WANA - WLE - FC)</td>
            <td></td>
            <td>1,568</td>
        </tr>
    </table>
    )
  }
}
