import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(wtp, source, capacity) {
  id = id + 1;
  return { id, wtp, source, capacity};
}

const rows = [
  createData('LRA Tondulu I (Tambunan Lama)', 'Sg Tondulu', '8'),
  createData('LRA Tondulu II (ABJV) (Tambunan Baru)', 'Sg Tondulu', '5'),
  createData('LRA Kg Bingkor (ABJV)', 'Sg Bayayo', '20'),
  createData('LRA Kg Bunsit', 'Sg Bayayo', '70'),
  createData('LRA Kg Keningau', 'Sg Liawan', '5'),
  createData('LRA Kg Agudon', 'Sg Pampang', '8'),
  createData('LRA Pekan Sook I', 'Sg Sook', '1.5'),
  createData('LRA Sook II', 'Sg Sook', '2'),
  createData('LRA Kemabong', 'Sg Padas', '6'),
  createData('LRA Senagang I & II', 'Sg Masalog', '12'),
  createData('LRA Pekan Tenom', 'Sg Padas', '4.5'),
  createData('LRA Montenior (ABJV) Beaufort 1', 'Sg Padas', '30'),
  createData('LRA Montenior (ABJV) Beaufort 2', 'Sg Padas', '30'),
  createData('LRA EWSS Limbawang', 'Sg Padas', '20'),
  createData('LRA Lawa Gadong', 'Sg Padas', '76'),
  createData('Rumah Pam Kiawayan (Kg Lalapakon)', 'Sg Pegalan', '30'),
  createData('Rumah Pam Karanaan A6 (Kg Karanaan)', 'Sg Pegalan', '9'),
  createData('Rumah Pam Timbou (Kg Karanaan)', 'Sg Pegalan', '9'),
  createData('Rumah Pam Maras Karas A3E (Kg Maras Karas)', 'Sg Pegalan', '17'),
  createData('Rumah Pam Stesen 3C Kg Lumondou', 'Sg Tambunan', '22'),
  createData('Rumah Pam Kg Mangi Pangi', 'Sg Kiawayan', '5'),
  createData('Rumah Pam Kepayan', 'Sg Pegalan', '14'),
  createData('Rumah Pam Bumburing II', 'Sg Pegalan', '26'),
  createData('Rumah Pam Kg Mailo Transpegalan 2', 'Sg Mailo', '65'),
  createData('Rumah Pam Bumboring 1', 'Sg Bayayo', '17'),
  createData('Rumah Pam Penagatan', 'Sg Pegalan', '30'),
  createData('Rumah Pam Lagud Seberang', 'Sg Padas', '43'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Water Users Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Users</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Source</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Design Capacity/Pump Capacity (MLD)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.wtp}</TableCell>
              <TableCell align="center">{row.source}</TableCell>
              <TableCell align="center">{row.capacity}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Total</TableCell>
            <TableCell></TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>585</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
