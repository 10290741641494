import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

// Generate Order Data
function createData(id, parameter, unit, class1, class2, class3, class4, class5) {
  return { id, parameter, unit, class1, class2, class3, class4, class5};
}

const rows = [
  createData(0, 'Ammoniacal Nitrogen', 'mg/l', '<0.1', '0.1-0.3', '0.3-0.9', '0.9-2.7', '>2.7',),
  createData(1, 'Biological Oxygen Demand', 'mg/l', '<1', '1-3', '3-6', '6-12', '>12',),
  createData(2, 'Chemical Oxygen Demand', 'mg/l', '<10', '10-25', '25-50', '50-100', '>100'),
  createData(3, 'Dissolved Oxygen', 'mg/l', '<7', '5-7', '3-5', '1-3', '<1',),
  createData(4, 'pH', '-', '<7', '6-7', '5-6', '<5', '>5',),
  createData(5, 'Total Suspended Solid', 'mg/l', '<25', '25-50', '50-150', '150-300', '>300',),
  createData(6, 'WQI ', '', '>92.7', '76.5-92.7', '51.9-76.5', '31-51.9', '<31',),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>WQI Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{fontWeight: 'bold'}}>Parameter</TableCell>
            <TableCell rowSpan={2} sx={{fontWeight: 'bold'}}>Unit</TableCell>
            <TableCell colSpan={5} align="center" sx={{fontWeight: 'bold'}}>Class</TableCell>
            {/* <TableCell>Payment Method</TableCell>
            <TableCell align="right">Sale Amount</TableCell> */}
          </TableRow>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>I</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>II</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>III</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>IV</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>V</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.parameter}</TableCell>
              <TableCell>{row.unit}</TableCell>
              <TableCell>{row.class1}</TableCell>
              <TableCell>{row.class2}</TableCell>
              <TableCell>{row.class3}</TableCell>
              <TableCell>{row.class4}</TableCell>
              <TableCell>{row.class5}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
