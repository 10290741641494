import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WavesIcon from '@mui/icons-material/Waves';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from '@mui/icons-material/StarBorder';


import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LayersIcon from '@mui/icons-material/Layers';
import WorkSpacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import BrandingWaterMarkIcon from '@mui/icons-material/BrandingWatermark';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import BathroomIcon from '@mui/icons-material/Bathroom';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import WbShadeIcon from '@mui/icons-material/WbShade';
import WaterIcon from '@mui/icons-material/Water';

export default function NestedList(props) {
  const [openStage1, setStage1Open] = React.useState(false);
  const [openStage2, setStage2Open] = React.useState(false);
  const [openStage3, setStage3Open] = React.useState(false);
  const [openStage4, setStage4Open] = React.useState(false);
  const [openSecondLevel, setOpenSecondLevel] = React.useState(false);

  const handleStage1Click = () => {
    setStage1Open(!openStage1);
  };

  const handleStage2Click = () => {
    setStage2Open(!openStage2);
  };

  const handleStage3Click = () => {
    setStage3Open(!openStage3);
  };

  const handleStage4Click = () => {
    setStage4Open(!openStage4);
  };

  const handleClickSecondLevel = () => {
    setOpenSecondLevel(!openSecondLevel);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={props.open? subHeader: ''}>
      
      {props.open? <Divider />: ''}

      <ListItemButton onClick={handleStage1Click}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 1" />
      {openStage1 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
          <Collapse in={openStage1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}} >
                <ListItemIcon>
                  <HowToVoteIcon />
                </ListItemIcon>
                <ListItemText primary="Water Demand" key={0} onClick={props.onClick} />
              </ListItemButton>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}} >
                <ListItemIcon>
                  <WaterDamageIcon />
                </ListItemIcon>
                <ListItemText primary="Water Allocation" key={1} onClick={props.onClick} />
              </ListItemButton>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}} >
                <ListItemIcon>
                  <LocalDrinkIcon />
                </ListItemIcon>
                <ListItemText primary="Water Priorisation" key={2} onClick={props.onClick}/>
              </ListItemButton>
              {/* <ListItemButton sx={{pl: 4}} onClick={handleClickSecondLevel}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Others" />
                  {openSecondLevel ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
                  <Collapse in={openSecondLevel} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 8, whiteSpace: 'normal'}} onClick={props.onClick}>
                              <ListItemIcon>
                                <BusinessCenterIcon />
                              </ListItemIcon>
                              <ListItemText primary="Plannning DMSS Scenario" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8, whiteSpace: 'normal' }} onClick={props.onClick}>
                              <ListItemIcon>
                                <InvertColorsIcon />
                              </ListItemIcon>
                              <ListItemText primary="Supply Demand Curve" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }} onClick={props.onClick}>
                              <ListItemIcon>
                                <LayersIcon />
                              </ListItemIcon>
                              <ListItemText primary="Landuse Map" />
                          </ListItemButton>
                          <ListItemButton sx={{ pl: 8 }} onClick={props.onClick}>
                              <ListItemIcon>
                                <BrandingWaterMarkIcon />
                              </ListItemIcon>
                              <ListItemText primary="WRCP Zone" />
                          </ListItemButton>
                      </List>
                  </Collapse> */}
            </List>
          </Collapse>

    <ListItemButton onClick={handleStage2Click}>
      <ListItemIcon>
        <AccountTreeIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 2" />
      {openStage2 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
        <Collapse in={openStage2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}}>
                <ListItemIcon>
                  <OilBarrelIcon />
                </ListItemIcon>
                <ListItemText primary="Water Resource Index & Drought Index" key={3} onClick={props.onClick}/>
              </ListItemButton>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}}>
                <ListItemIcon>
                  <WaterfallChartIcon />
                </ListItemIcon>
                <ListItemText primary="Water Availability" key={4} onClick={props.onClick}/>
              </ListItemButton>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}}>
                <ListItemIcon>
                  <BathroomIcon />
                </ListItemIcon>
                <ListItemText primary="Water Quality" key={5} onClick={props.onClick}/>
              </ListItemButton>
            </List>
          </Collapse>

    <ListItemButton onClick={handleStage3Click}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 3" />
      {openStage3 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
          <Collapse in={openStage3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}} >
                <ListItemIcon>
                  <WbShadeIcon />
                </ListItemIcon>
                <ListItemText primary="Dam Release & Storage" key={6} onClick={props.onClick}/>
              </ListItemButton>
              {/* <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}}>
                <ListItemIcon>
                  <WaterIcon />
                </ListItemIcon>
                <ListItemText primary="Dam Status (Weekly)" />
              </ListItemButton>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}}>
                <ListItemIcon>
                  <CloudSyncIcon />
                </ListItemIcon>
                <ListItemText primary="Water Treatment Status (Monthly)" />
              </ListItemButton> */}
            </List>
          </Collapse>

    <ListItemButton onClick={handleStage4Click}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 4" />
      {openStage4 ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openStage4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}} >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Water Accounting" key={7} onClick={props.onClick}/>
              </ListItemButton>
              <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}} >
                <ListItemIcon>
                  <LibraryAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary="Water Auditing" key={8} onClick={props.onClick}/>
              </ListItemButton>
              {/* <ListItemButton sx={{pl: 4, whiteSpace: 'normal'}}>
                <ListItemIcon>
                  <WorkSpacePremiumIcon />
                </ListItemIcon>
                <ListItemText primary="Forecast Performance Report/Hit (Monthly)" />
              </ListItemButton> */}
            </List>
          </Collapse>
  </List>
  );
}

const subHeader = (
  <ListSubheader component="div" id="nested-list-subheader" sx={{fontSize:'18px', fontWeight: 'bold'}}>
      Sungai Padas
  </ListSubheader>
);

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <WavesIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 1" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AccountTreeIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 2" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 3" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Stage 4" />
    </ListItemButton>
    {/* <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
