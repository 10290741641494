import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { withStyles } from '@mui/styles';

let id = -1
// Generate Order Data
function createData(scale, climate, resource) {
  id = id + 1;
  return { id, scale, climate, resource};
}

const rows = [
  createData('WRI ≥ 1', 'Flooding', 'Wet Season in river and reservoir over spilling flood water'),
  createData('0.6 ≤ WRI < 1', 'Normal Weather', 'In-stream and reservoir storage enough for consumption and utilization'),
  createData('0.4 ≤ WRI < 0.6', 'Moderate Drought', 'In-stream and reservoir storage just barely enough for consumption and utilization'),
  createData('0.2 ≤ WRI < 0.4', 'Severe Drought', 'In-stream and reservoir storage has a risk of not enough for consumption and utilization'),
  createData('0 ≤ WRI < 0.2', 'Extreme Drought', 'In-stream and reservoir storage not enough for consumption and utilization'),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>WRI Table</Title>
      <Table size="small" border="1px solid #e0e0e0" sx={{p: 5, border: '1px solid #e0e0e0'}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>WRI Scale</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Climatic Conditions</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Water Resources Conditions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.scale}</TableCell>
              <TableCell align="center">{row.climate}</TableCell>
              <TableCell align="center">{row.resource}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
